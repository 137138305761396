<template>
  <div
    id="print_invoice_pdf"
    style="background-color: white;font-size: 22px;font-weight: 700;color: black;"
  >
    <div style="display: flex;">
      <div class="col-md-6">
        <br>
        <img
          src="@/assets/images/logo.png"
          class="img-print-invoice"
          style="height: 200px;"
        >
      </div>
      <div class="col-md-6">
        <div style="text-align: right;line-height: 15px;">
          <div style="font-size: 8px;color: #7a7a7a">
            บริษัท วอท ยู วอนท์ 59 จํากัด (สํานักงานใหญ่)
          </div>
          <div style="font-size: 8px;color: #7a7a7a;margin-top: -5px;">
            7/272 หมู่5 ตำบล บางใหญ่ อำเภอบางใหญ่ จังหวัดนนทบุรี  11140
          </div>
        </div>
        <table style="margin-top: 15px;">
          <thead>
            <tr>
              <th style="width: 5%;"></th>
              <th style="width: 55%; background-color: black; border-radius: 15px 0 0;">
                <div style="padding: 10px;">
                  <center>
                    <span style="font-size: 30px; color: white; font-weight: 700;">
                      Purchase Order
                    </span>
                    <br> <span style="color: white; font-weight: 700; padding: 5px;font-size: 18px;">ใบสั่งซื้อ</span>
                    <br>
                  </center>
                </div>
              </th>
              <th style="width: 40%; border: 1px solid black;padding: 0">
                <center>
                  <span style="font-size: 14px;"> ต้นฉบับ/Original </span> <br>
                  <hr style="border: 1px solid black;">
                  <!--                <img :src="order.path_qr" width="50%" onerror="imgError(this);">-->
                  <!--                <v-img :src="path_qr" -->
                  <!--                       width="50%"></v-img>-->

                  <!--                :size="size"-->
                  <qr-code
                    v-if="order && order.order_inv"
                    style="margin-top: 15px;"
                    :size="size"
                    :text="order ? order.order_inv : ''"
                  ></qr-code>
                  <p style="font-size: 15px;">
                    {{ order ? order.order_inv : '' }}&nbsp;&nbsp;&nbsp;&nbsp;
                  </p>
                </center>
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
    <div style="display: flex;margin-top: -35px;padding:0">
      <div class="col-md-12">
        <table class="table-text-supplier">
          <thead>
            <tr>
              <th
                class=""
                style="width: 40%;"
              >
                <span>ชื่อลูกค้า</span> : {{ order.member_name }} / {{ order.member_shop_name }}<br>
                <span
                  style="font-size: 14px;"
                >Customer Name</span><br><br>
                <span>เบอร์โทรศัพท์ : {{ order.member_tele }}</span><br> <span
                  style="font-size: 14px;"
                >Phone</span><br><br>
                <span>ที่อยู่ : </span><br> <span style="font-size: 14px;">Address</span><br>&nbsp;
                &nbsp;
                <span>{{ order.member_shop_address }}<br><br></span>
              </th>
              <th
                class="border-black"
                style="width: 35%;"
              >
                <span>เลขที่  {{
                  order.order_inv ? order.order_inv : ''
                }}   {{ order.by_claim_at ? '(บิลเคลม)' : '' }}</span><br>
                <span style="font-size: 14px;">Order Number</span><br><br>
                <span>วันที่ : {{ order.order_date_time }} น.</span><br>
                <span style="font-size: 14px;">Issue Date</span><br><br>
                <span>วันที่ชำระ :
                  <span v-if="order.PT_date && order.PT_time">
                    {{ order.PT_date }} {{ order.PT_time }}
                  <!--                   {{substr($order->PT_date,0,10)}} {{$order->PT_time}}  น.-->
                  </span>
                </span>
                <br>
                <span style="font-size: 14px;">Payment Date</span><br><br>
                <span>การชำระเงิน : </span><br>
                <span style="font-size: 14px;">Credit Term</span><br>
                <span v-if="order.PT_type === 'C'">เงินสด ,</span>
                <span v-if="order.PT_type === 'T'">โอน ,</span>
                <span
                  v-if="order.PT_type === 'T' && order.additional_payment === 'Y'"
                >เครดิตสินค้า ,</span>
                <span v-if="order.PT_type === 'T' && order.additional_payment === 'Y'"> โอนส่วนต่าง+ค่าจัดส่ง ,</span>
                <span v-if="order.PT_slip && order.additional_payment === 'Y' && order.PT_date">กระเป๋าออนไลน์ ,</span>
                <span v-if="order.PT_type === 'CD'">บัตรเครดิต ,</span>
                <span v-if="order.PT_type === 'CC'"> กระเป๋าออนไลน์ ,</span>
                <span v-if="order.PT_type === 'CP'"> เครดิตเสินค้า ,</span>
                <span v-if="order.PT_type === 'DP'"> ลูกค้ามัดจำ ,</span>
                <br>
              </th>
              <th
                class="border-black"
                style="width: 25%;"
              >
                <span>จัดทำโดย : {{ order.employee ? order.employee.employee_name : '-' }}</span><br>
                <span
                  style="font-size: 14px;"
                >PrePared By</span> <br> <br> <br> <br> <br> <br> <br> <br>
                <br>
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
    <div
      class="col-12"
      style="margin-top:-25px;padding-left: 12px;padding-right: 12px;"
    >
      <table style="width: 100%;">
        <thead
          class="border-black"
          style="background: black; color: white;"
        >
          <tr>
            <th style="text-align: center; padding: 5px;font-size: 16px;width: 8%">
              เลขที่<br>No.
            </th>
            <th
              colspan="2"
              style="text-align: center; padding: 10px;font-size: 16px;"
            >
              รายการ <br>Descriptions
            </th>
            <th style="text-align: center; padding: 5px;font-size: 16px;width: 10%">
              จำนวน <br>Quantity
            </th>
            <th style="text-align: center; padding: 5px;width: 10%;font-size: 16px;">
              ราคา/หน่วย <br>Unit
              Price
            </th>
            <th style="text-align: center; padding: 5px;width: 10%;font-size: 16px;">
              จำนวนเงิน (THB)
              <br> Amount
            </th>
          </tr>
        </thead>
        <tbody class="border-black">
          <!--        <?php $amount = 0;?>-->
          <!--        <?php $total = 0;?>-->
          <!--        <?php $i = 0 ?>-->
          <tr v-for="(order_detail,index) in order_details">
            <td
              style="text-align: center;border-right: 1px solid black;font-size: 18px;font-weight: 700;color: black;"
            >
              <b>{{ index + 1 }}</b>
            </td>
            <td
              colspan="2"
              style="text-align: left;border-right: 1px solid black;font-size: 18px;font-weight: 700;color: black;"
            >
              <span style="margin-left: 10px;font-weight: 700;color: black;">
                {{ order_detail.pattern ? order_detail.pattern.pattern_code : '' }}
              </span>
              <span style="font-size: 18px;font-weight: 700;color: black;">  {{ order_detail.pattern ? order_detail.pattern.pattern_name : order_detail.pattern_name }}</span>
              <span style="font-size: 18px;font-weight: 700;color: black;">  {{ (order_detail.product && order_detail.product.brand) ? order_detail.product.brand.phone_brand_name : order_detail.phone_brand_name }}</span>
              <span style="font-size: 18px;font-weight: 700;color: black;">  {{ (order_detail.product && order_detail.product.phone_model) ? order_detail.product.phone_model.phone_model_name : order_detail.phone_model_name }}</span>
            </td>
            <td
              style="width: 10%; text-align: center;border-right: 1px solid black;font-size: 18px;font-weight: 700;color: black;"
            >
              <b>{{ order_detail.product_amount }}</b>
            </td>
            <td
              style="width: 10%; text-align: right;border-right: 1px solid black;font-size: 18px;font-weight: 700;color: black;"
            >
              <b><span>
                {{ order_detail.product_price }}
                <!--              {{ number_format(((float) $order_detail->product_price), 2, '.', '') }} -->
              </span></b>
            </td>
            <td style="text-align: right;font-size: 18px;font-weight: 700;color: black;">
              <b><span>
                {{ (order_detail.product_price * order_detail.product_amount).toFixed(2) }}
                <!--              {{ number_format(((float) $order_detail->product_price)*((float) $order_detail->product_amount), 2, '.', '') }}-->
              </span></b>
            </td>
          </tr>
          <!--        <?php $total += number_format((float)$order_detail->product_price *-->
          <!--        $order_detail->product_amount, 2, '.', '');-->
          <!--        $amount+= $order_detail->product_amount;-->
          <!--        ?>-->

          <!--        <?php $count = count($order_details)  ?>-->
          <!--        @if($count < 5)-->
          <!--        @for ($i = 4; $i >= $count; $i--)-->
          <tr
            v-for="index in length_for"
            v-if="purchase_order_details_length < 10"
          >
            <td style="text-align: center;border-right-style: solid;border-right-color: black;">
              <p
                style="color: white"
              >
                -
              </p>
            </td>
            <td
              colspan="2"
              style="text-align: center;border-right-style: solid;border-right-color: black;"
            >
              <p
                style="color: white"
              >
                -
              </p>
            </td>
            <td
              style="width: 10%;text-align: center;border-right-style: solid;border-right-color: black;"
            >
              <p
                style="color: white"
              >
                -
              </p>
            </td>
            <td style="border-right-style: solid;border-right-color: black;">
              <p style="color: white">
                -
              </p>
            </td>
            <td style="width: 10%;border-right-style: solid;border-right-color: black;">
              <p
                style="color: white"
              >
                -
              </p>
            </td>
          </tr>
          <!--        @endfor-->
          <!--        @endif-->
          <!--        <?php-->
          <!--            $sumTotal =  $total;-->
          <!--            $vat_before = 0.00;-->
          <!--            $vat_after = 0.00;-->

          <!--            //ก่อน vat-->
          <!--            $vat_before = number_format((float)(($order->order_expense * 100) / 107), 2, '.', '');;-->

          <!--        //หลัง vat-->
          <!--        $vat_after = $order->order_expense - $vat_before-->
          <!--        // $vat_after = $order->order_expense - $vat_calculator;-->
          <!--        // $balance = $order->order_expense-$vat;-->
          <!--        ?>-->
        </tbody>
      </table>
    </div>
    <!--    <br v-if="order_details.length > 20">-->
    <!--    <br v-if="order_details.length > 20">-->
    <!--    <br v-if="order_details.length > 20">-->
    <!--    <br v-if="order_details.length > 20">-->

    <div
      class="col-12"
      style="padding: 0 12px;"
    >
      <div style="display: flex;">
        <table style="width: 50%;">
          <thead>
            <tr
              class="table-text-amount"
              style="text-align: left;background-color: #eae9e9;height: 20px;"
            >
              <th>
                <span style="padding: 5px;">จำนวนเงิน</span> <br>
                <span style="font-size: 13px; padding: 5px;">Amount</span>
              </th>
              <th><span style="padding: 5px;">{{ order.BAHT_text }}</span></th>
            </tr>
            <tr>
              <th
                colspan="2"
                style="text-align: center;"
              >
                <div v-if="order.PT_type === 'T' || order.PT_type === 'DP'">
                  <div v-if="order.order_media">
                    <v-img
                      v-for="(orderMedia,index) in order.order_media"
                      :src="orderMedia.media.path"
                      style="width: 200px;padding: 5px;margin: 0 auto;"
                    >
                    </v-img>
                  </div>
                  <div v-if="!order.order_media">
                    <v-img
                      style="width: 30%;padding: 5px;"
                      :src="deposit.PT_slip"
                    ></v-img>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
        </table>
        <table
          class="table-summary"
          style="width: 45%;"
        >
          <thead>
            <tr>
              <th class="table-text">
                <span style="font-size: 22px;font-weight: 700;color: black">จำนวนสินค้าทั้งหมด</span> <br>
                <span
                  style="font-size: 17px;"
                >Amount</span>
              </th>
            </tr>
            <tr>
              <th class="table-text">
                <span style="font-size: 22px;font-weight: 700;color: black">รวมเป็นเงิน</span> <br>
                <span style="font-size: 17px;">SubTotal</span>
              </th>
            </tr>
            <tr>
              <th class="table-text">
                <span style="font-size: 22px;font-weight: 700;color: black">หักส่วนลดพิเศษ</span> <br>
                <span style="font-size: 17px;">Special discount</span>
              </th>
            </tr>
            <tr>
              <th class="table-text">
                <span style="font-size: 22px;font-weight: 700;color: black">ค่าบริการเร่งด่วน</span> <br>
                <span
                  style="font-size: 17px;"
                >Express</span>
              </th>
            </tr>
            <tr>
              <th class="table-text">
                <span style="font-size: 22px;font-weight: 700;color: black">ค่าบริการอื่นๆ</span> <br>
                <span style="font-size: 17px;">Other</span>
              </th>
            </tr>
            <tr>
              <th class="table-text">
                <span style="font-size: 22px;font-weight: 700;color: black">ยอดรวมทั้งสิ้น</span> <br>
                <span style="font-size: 17px;">Total</span>
              </th>
            </tr>
          </thead>
        </table>
        <table
          class="table-summary "
          style="width: 15%;border-spacing: 0"
        >
          <thead
            class="border-black"
            style="border-spacing: 0"
          >
            <tr class="border-black">
              <th
                class="table-total"
                style="text-align: right;font-size: 22px;font-weight: 700;color: black"
              >
                <p>{{ amount - 1 }} ชิ้น</p>
              </th>
            </tr>
            <tr class="border-black">
              <th
                class="table-total"
                style="text-align: right;font-size: 22px;font-weight: 700;color: black"
              >
                <p>
                  {{
                    (parseFloat(total).toFixed(2))
                  }}
                </p>
              </th>
            </tr>
            <tr
              class="border-black"
              style="text-align: right;"
            >
              <th
                class="table-total"
                style="font-size: 22px;font-weight: 700;color: black"
              >
                <p>{{ parseFloat(order.order_price_discount ? order.order_price_discount : 0) }}</p>
              <!--              <p>{{ number_format((float)($order->order_price_discount ? : 0), 2, '.', '') }}</p>-->
              </th>
            </tr>
            <tr
              class="border-black"
              style="text-align: right;"
            >
              <th
                class="table-total"
                style="font-size: 22px;font-weight: 700;color: black"
              >
                <p v-if="order.importune === 'Y'">
                  <span v-if="(amount-1)< 13"> {{ parseFloat(amount - 1) * 20 }}</span>
                  <span v-if="(amount-1) > 12 && (amount-1) < 50"> {{
                    parseFloat(amount - 1) * 15
                  }}</span>
                  <span v-if="(amount-1) > 50"> {{ parseFloat(amount - 1) * 10 }}</span>
                <!--                  @elseif(($amount-1) > 12 )-->
                <!--                  {{ number_format((float)(($amount - 1) * 15 ? : 0), 2, '.', '') }}-->
                <!--                  @else-->
                <!--                  {{ number_format((float)(($amount - 1) * 10 ? : 0), 2, '.', '') }}-->
                <!--                  @endif-->
                </p>
                <p v-if="order.importune !== 'Y'">
                  0
                </p>
              </th>
            </tr>
            <tr
              class="border-black"
              style="text-align: right;"
            >
              <th
                class="table-total"
                style="font-size: 22px;font-weight: 700;color: black"
              >
                <!--              <p>{{ number_format((float)($order->configOrder ? : 0), 2, '.', '') }}</p>-->
                <p>{{ (parseFloat(order.configOrder ? order.configOrder : 0)) }}</p>
              </th>
            </tr>
            <tr
              class="border-black"
              style="text-align: right;"
            >
              <th
                class="table-total"
                style="padding-right: 3px;background-color: black;font-size: 22px;font-weight: 700;color: black"
              >
                <span
                  v-if="order.PT_type === 'T' && order.additional_payment === 'Y'"
                  style="text-decoration: line-through;color: white"
                >{{ order.order_expense }}</span>
                <span style="text-align:right;color: white">{{ order.order_expense }}</span>
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <table style="width: 100%;margin-top: 15px;">
        <thead>
          <tr style="text-align: center;font-size: 12px;">
            <th style="border: 1px solid black;">
              <br>
              <div>_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _</div><br>
              <div>ผู้ตรวจสอบ / Approver</div><br>
              <div>วันที่ / Date _ _ _ _ _ _ _ _ _ _ _ _ _ _ </div>
            </th>
            <th style="border: 1px solid black;">
              <span style="color: white;"><br><br>
                _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _
              </span>
            </th>
            <th style="border: 1px solid black;">
              <br>
              <div>_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ </div><br>
              <div>ผู้มีอำอาจลงนาม / Authorized Signature </div><br>
              <div>วันที่ / Date _ _ _ _ _ _ _ _ _ _ _ _ _ _ </div>
            </th>
          </tr>
        </thead>
      </table>
      <table
        style="width: 100%; background-color: black; border-radius: 0 0 15px 15px; color: white;"
      >
        <tr style="padding: 0">
          <th style="text-align: center;line-height: 15px;padding-top: 0">
            <div
              style="font-size: 11px;"
            >
              บริษัท วอท ยู วอนท์ 59 จํากัด (สํานักงานใหญ)
            </div>
            <div style="font-size: 11px;">
              7/272 หมู่5 ตำบล บางใหญ่ อำเภอบางใหญ่ จังหวัดนนทบุรี  11140
            </div>
          </th>
        </tr>
      </table>
    </div>

    <div
      id="order_detail"
      class="container"
      style="background-color: white;font-size: 22px;font-weight: 700;color: black;padding: 50px 140px;"
    >
      <div
        class="row"
        style=""
      >
        <div class="col-md-12 row">
          <div class="col-md-12 row">
            <table
              style="width: 100%;"
              width="100%"
            >
              <tr>
                <td width="50%"></td>
                <td
                  style="text-align: right"
                  width="50%"
                >
                  <div class="float-right">
                    <!--                  <img :src="order.path_qr" width="35%">-->

                    <!--                    :size="size"-->
                    <qr-code
                      v-if="order && order.order_inv"
                      :size="size"
                      style="margin-right:50px;float: right"
                      :text="order.order_inv"
                    ></qr-code>
                    <br>
                    <b style="font-size: 22px;font-weight: 700;color: black;margin-top: 10px;">{{ order.order_inv }}
                    &nbsp;&nbsp;</b>&nbsp;
                    <br>
                  </div>
                  <br><br>
                </td>
              </tr>
              <tr>
                <td width="50%"></td>
                <td
                  style="text-align: right"
                  width="50%"
                >
                  <div class="float-lg-right">
                    <div v-for="(config,index) in configs">
                      <div v-if="config.check && config.check === true">
                        <input
                          v-model="config.check"
                          type="checkbox"
                          checked
                        >
                        <span style="color: #ff4d4d"> {{ config.name }}</span>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <table style="width: 100%">
            <tr>
              <td style="width: 50%;text-align: left">
                <b style="text-align: left;font-size: 22px;font-weight: 700;color: black;">ชื่อและที่อยู่ผู้ส่ง
                  <br></b>
                <b
                  style="text-align: left;font-size: 22px;font-weight: 700;color: black;"
                >{{
                  order.sender_name ? order.sender_name : 'whatyouwantshop'
                }} </b><br>
                <b
                  style="text-align: left;font-size: 22px;font-weight: 700;color: black;"
                >{{
                  order.sender_address ? order.sender_address : '89/321 มบ ทาวน์อเวนิว เมิร์จ รัตนาธิเบศร์ ม10  ซอยวัดมะเดื่อ ต บางรักใหญ่ อ บางบัวทอง จ นนทบุรี 11110 T.0956965451 *ได้รับสินค้าแล้วถ่ายรีวิวมาอวดกันบ้างนะครับ*'
                }} </b><br>
                <br>
              </td>
              <td style="width: 50%;text-align: left">
                <br>
              </td>
            </tr>
            <tr>
              <td style="width: 50%;text-align: left">
                <br>
              </td>
              <td style="width: 50%;text-align: left">
                <b style="text-align: left;font-size: 22px;font-weight: 700;color: black;"> &nbsp;
                  &nbsp;ชื่อและที่อยู่ผู้รับ
                  <br></b>
                <b style="text-align: left;font-size: 22px;font-weight: 700;color: black;"> &nbsp;
                  &nbsp; &nbsp;
                  &nbsp; &nbsp;{{ order.recipient_name ? order.recipient_name : '-' }} </b><br>
                <b
                  v-if="order.recipient_address && order.recipient_address != 'null' && order.recipient_address != null"
                  style="text-align: left;font-size: 22px;font-weight: 700;color: black;"
                >
                  &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp;{{ order.recipient_address ? order.recipient_address : '-' }}
                </b><br>

                <br>
              </td>
            </tr>
          </table>
        </div>

        <table style="width:50%">
          <tr style="width:100%">
            <td
              v-for="(order_detail,index) in print_envelope_order_details "
              style="text-align: center"
            >
              <div v-if="show === 'Y'">
                <div

                  v-if="order_detail.pattern && order_detail.pattern.location_files"
                  style="width: 100px"
                >
                  <span
                    v-if="order_detail.pattern.type_link_add_files ===1 || order_detail.pattern.type_link_add_files === '1'"
                  >
                    <v-img
                      v-if="order_detail.pattern && order_detail.pattern.location_files"
                      style="width:90px;"
                      :src="order_detail.pattern.location_files"
                    ></v-img>
                  </span>
                  <span
                    v-if="order_detail.pattern.type_link_add_files !==1 && order_detail.pattern.type_link_add_files !== '1'"
                  >
                    <br><br>
                    <!--                <a :href="order_detail.location_files" target="_blank">ไฟล์เพิ่มเติม</a>-->
                    <router-link
                      v-if="order_detail.pattern_img_product && order_detail.pattern_img_product.pattern_img"
                      target="_blank"
                      :to="order_detail.pattern_img_product.pattern_img"
                    >
                      ไฟล์เพิ่มเติม
                    </router-link><br> <br>
                  </span>
                </div>
                <div
                  v-if="!order_detail.pattern || !order_detail.pattern.location_files"
                  style="width: 90px"
                >
                  <v-img
                    v-if="order_detail.pattern_img_product && order_detail.pattern_img_product.pattern_img"
                    style="width:90px;;object-fit: contain;"
                    :src="order_detail.pattern_img_product.pattern_img"
                  ></v-img>
                </div>
                <div
                  class="card-text"
                  style="font-size: 22px;font-weight: 700;color: black;width: 100px"
                >
                  รุ่น:
                  {{
                    order_detail.product && order_detail.product.phone_model ? order_detail.product.phone_model.phone_model_name : '-'
                  }}
                </div>
              </div>
            </td>
          </tr>
          <!--        <tr style="width:100%">-->
          <!--          <td style="text-align: center">-->
          <!--            @if($order_detail->location_files)-->
          <!--              @if($order_detail->type_link_add_files ===1 || $order_detail->type_link_add_files === '1')-->
          <!--              <img style="width: {{$width? :100}}px;height: {{$height? :100}}px;" src="-->
          <!--                                          {{$order_detail->location_files}}-->
          <!--                                              " onerror="imgError(this);">-->
          <!--              @else-->
          <!--              <br><br>-->
          <!--              <a href="{{$order_detail->location_files}}" target="_blank">ไฟล์เพิ่มเติม</a>-->
          <!--              <br> <br>-->
          <!--              @endif-->
          <!--            @else-->
          <!--            <img style="width: {{$width? :100}}px;height: {{$height? :100}}px;" src="-->
          <!--                                        {{$order_detail->pattern_img}}-->
          <!--                                        " onerror="imgError(this);">-->
          <!--            @endif-->
          <!--            <br>-->
          <!--            <p class="card-text" style="font-size: 16px;font-weight: 700;color: black;">-->
          <!--              รุ่น: {{$order_detail->phone_model_name}}</p>-->
          <!--          </td>-->
          <!--        </tr>-->
          <!--        {{// &#45;&#45; $index > 12 && $index <= 18&#45;&#45;}}-->
          <!--        <tr style="width:100%">-->
          <!--          @foreach($print_envelope_order_details as $index =>  $order_detail)-->
          <!--          @if($index > 12 && $index <= 18 )-->
          <!--          <td style="text-align: center">-->
          <!--            @if($show === 'Y')-->
          <!--            @if($order_detail->location_files)-->
          <!--            @if($order_detail->type_link_add_files ===1 || $order_detail->type_link_add_files === '1')-->
          <!--            <img style="width: {{$width? :100}}px;height: {{$height? :100}}px;" src="-->
          <!--                                        {{$order_detail->location_files}}-->
          <!--                                            " onerror="imgError(this);">-->
          <!--            @else-->
          <!--            <br><br>-->
          <!--            <a href="{{$order_detail->location_files}}" target="_blank">ไฟล์เพิ่มเติม</a>-->
          <!--            <br> <br>-->
          <!--            @endif-->
          <!--            @else-->
          <!--            <img style="width: {{$width? :100}}px;height: {{$height? :100}}px;" src="-->
          <!--                                        {{$order_detail->pattern_img}}-->
          <!--                                        " onerror="imgError(this);">-->
          <!--            @endif-->
          <!--            <br>-->
          <!--            <p class="card-text" style="font-size: 16px;font-weight: 700;color: black;">-->
          <!--              รุ่น: {{$order_detail->phone_model_name}}</p>-->
          <!--            @endif-->
          <!--          </td>-->
          <!--          @endif-->
          <!---->
          <!--          @endforeach-->
          <!--        </tr>-->

          <!--        <tr style="width:100%">-->
          <!--          @foreach($print_envelope_order_details as $index =>  $order_detail)-->
          <!--          @if($index > 18 && $index <= 24 )-->
          <!--          <td style="text-align: center">-->
          <!--            @if($show === 'Y')-->
          <!--            @if($order_detail->location_files)-->
          <!--            @if($order_detail->type_link_add_files ===1 || $order_detail->type_link_add_files === '1')-->
          <!--            <img style="width: {{$width? :100}}px;height: {{$height? :100}}px;" src="-->
          <!--                                        {{$order_detail->location_files}}-->
          <!--                                            " onerror="imgError(this);">-->
          <!--            @else-->
          <!--            <br><br>-->
          <!--            <a href="{{$order_detail->location_files}}" target="_blank">ไฟล์เพิ่มเติม</a>-->
          <!--            <br> <br>-->
          <!--            @endif-->
          <!--            @else-->
          <!--            <img style="width: {{$width? :100}}px;height: {{$height? :100}}px;" src="-->
          <!--                                        {{$order_detail->pattern_img}}-->
          <!--                                        " onerror="imgError(this);">-->
          <!--            @endif-->
          <!--            <br>-->
          <!--            <p class="card-text" style="font-size: 16px;font-weight: 700;color: black;">-->
          <!--              รุ่น: {{$order_detail->phone_model_name}}</p>-->
          <!--            @endif-->

          <!--          </td>-->
          <!--          @endif-->

          <!--          @endforeach-->
          <!--        </tr>-->
          <!--        <tr style="width:100%">-->
          <!--          @foreach($print_envelope_order_details as $index =>  $order_detail)-->
          <!--          @if($index > 24 && $index <= 30 )-->
          <!--          <td style="text-align: center">-->
          <!--            @if($show === 'Y')-->
          <!--            @if($order_detail->location_files)-->
          <!--            @if($order_detail->type_link_add_files ===1 || $order_detail->type_link_add_files === '1')-->
          <!--            <img style="width: {{$width? :100}}px;height: {{$height? :100}}px;" src="-->
          <!--                                        {{$order_detail->location_files}}-->
          <!--                                            ">-->
          <!--            @else-->
          <!--            <br><br>-->
          <!--            <a href="{{$order_detail->location_files}}" target="_blank">ไฟล์เพิ่มเติม</a>-->
          <!--            <br> <br>-->
          <!--            @endif-->
          <!--            @else-->
          <!--            <img style="width: {{$width? :100}}px;height: {{$height? :100}}px;" src="-->
          <!--                                        {{$order_detail->pattern_img}}-->
          <!--                                        " onerror="imgError(this);">-->
          <!--            @endif-->
          <!--            <br>-->
          <!--            <p class="card-text" style="font-size: 16px;font-weight: 700;color: black;">-->
          <!--              รุ่น: {{$order_detail->phone_model_name}}</p>-->
          <!--            @endif-->

          <!--          </td>-->
          <!--          @endif-->

          <!--          @endforeach-->
          <!--        </tr>-->

          <!--        <tr style="width:100%">-->
          <!--          @foreach($print_envelope_order_details as $index =>  $order_detail)-->
          <!--          @if($index > 30 && $index <= 36 )-->
          <!--          <td style="text-align: center">-->
          <!--            @if($show === 'Y')-->
          <!--            @if($order_detail->location_files)-->
          <!--            @if($order_detail->type_link_add_files ===1 || $order_detail->type_link_add_files === '1')-->
          <!--            <img style="width: {{$width? :100}}px;height: {{$height? :100}}px;" src="-->
          <!--                                        {{$order_detail->location_files}}-->
          <!--                                            " onerror="imgError(this);">-->
          <!--            @else-->
          <!--            <br><br>-->
          <!--            <a style="font-size: 16px;font-weight: 700;color: black;"-->
          <!--               href="{{$order_detail->location_files}}" target="_blank">ไฟล์เพิ่มเติม</a>-->
          <!--            <br> <br>-->
          <!--            @endif-->
          <!--            @else-->
          <!--            <img style="width: {{$width? :100}}px;height: {{$height? :100}}px;" src="-->
          <!--                                        {{$order_detail->pattern_img}}-->
          <!--                                        " onerror="imgError(this);">-->
          <!--            @endif-->
          <!--            <br>-->
          <!--            <p class="card-textืน" style="font-size: 16px;font-weight: 700;color: black;">-->
          <!--              รุ่น: {{$order_detail->phone_model_name}}</p>-->
          <!--            @endif-->

          <!--          </td>-->
          <!--          @endif-->

          <!--          @endforeach-->
          <!--        </tr>-->
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiPencil, mdiDelete } from '@mdi/js'

// import config from '../../../config'
// import instance from '../../../axios.service'
import Swal from 'sweetalert2'
import { email, minLength, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import instance_order from '@/services/order'
import '@/assets/style/report.css'

export default {
  setup() {
    return {
      v$: useVuelidate(),
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    type: true ? 'ใบสั่งซื้อสินค้า Purchase Order' : 'ใบสั่งซื้อสินค้า Purchase Order',
    payment_types: [],
    order: {},
    configs: [],
    size: 100,
    print_envelope_order_details: [],
    order_details: [],
    data: {
      supplier_name: '',
      employee_name: '',
      employee_id: '',
      purchase_on: '',
      tax_id: '',
      address: '',
      date: '',
      payment_id: 'เลือกการชำระเงิน',
      contact_name: '',
      project_name: '',
      sub_total: 0,
      discount: 0,
      total: 0,
      vat: 0,
      note: '',
      is_vat: true,

    },
    purchase_order_details: [],
    amount_options: [
      1, 5, 10, 20, 50, 100, 500, 1000,
    ],
    amount: 1,
    total: 1,
    selected: [],
    deposit: {},
    product_options: [],
    count: 0,
    product_select: 'ค้นหาสินค้า',
    purchase_order_type: 1, // ใบสั่งซื้อสินค้า Purchase Order 1    //
    length_for: 0,
    purchase_order_details_length: 7,
  }),
  validations() {
    return {
      editedItem: {
        name: { required },
        value: { required },
      },
    }
  },
  computed: {},
  watch: {},

  created() {

  },
  mounted() {
    this.data.id = this.$route.params.id
    this.purchase_order_type = this.$route.query.purchase_order_type
    this.show = this.$route.query.show
    this.size = parseInt(this.$route.query.size)
    this.height = this.$route.query.height
    this.getInvoicePrint()
    this.getEnvelope()
  },
  methods: {
    getEnvelope() {
      const data = {
        order_id: this.data.id,
      }
      instance_order.getEnvelope(data).then(res => {
        this.configs = res.data.data.configs
        this.order = res.data.data.order
        this.configs = res.data.data.configs
        this.print_envelope_order_details = res.data.data.print_envelope_order_details
      }).catch(err => {

      })
    },
    sum() {
      this.amount = 0
      this.total = 0
      for (let i = 0; i < this.order_details.length; i++) {
        this.amount += this.order_details[i].product_amount
        this.total += this.order_details[i].product_amount * this.order_details[i].product_price
      }
    },
    getInvoicePrint() {
      const data = {
        order_id: this.data.id,
      }
      instance_order.getPrintInvoice(data).then(res => {
        // this.width = res.data.data.width;
        // this.height = res.data.data.height;
        // this.show = res.data.data.show;
        this.order = res.data.data.order
        this.order_details = res.data.data.order_details
        this.sum()
      }).catch(err => {

      })
    },
    imgError() {

    },
  },
}
</script>

<style>
</style>
